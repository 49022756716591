import React from "react"
import { EosSection, EosHeading, } from '../eos'
import { Columns } from "react-bulma-components"
import { StaticImage } from "gatsby-plugin-image"


const OurPartnersSection = () => {

    return (
        <EosSection
            backgroundColor='white-ter'
            style={{ paddingTop: '12rem' }}
        >
            <EosHeading
                renderAs='h2'
            >
                Our partners
            </EosHeading>
            <Columns justifyContent={'space-around'} style={{ filter: 'brightness(0.98)' }} breakpoint={'mobile'}>
                <Columns.Column mobile={{ size: 6 }} tablet={{ size: 3 }}>
                    <StaticImage
                        alt='Stop-Sound - Soundproofing and vibration damping solution from biosourced by products compounds'
                        src={`../../../static/logos/Stop_Sound_biosourced.png`}
                    />
                </Columns.Column>
                <Columns.Column mobile={{ size: 6 }} tablet={{ size: 3 }}>
                    <StaticImage
                        alt='Stop-Sound - Soundproofing and vibration damping solution supported by TUV biogradable'
                        src={`../../../static/logos/Stop_Sound_biodegradable_TUV.png`}
                    />
                </Columns.Column>
                <Columns.Column mobile={{ size: 6 }} tablet={{ size: 3 }}>
                    <StaticImage
                        alt='Stop-Sound - Soundproofing and vibration damping solution supported by Interreg'
                        src={`../../../static/logos/Stop_Sound_Innovation_Interreg.png`}
                    />
                </Columns.Column>
                <Columns.Column mobile={{ size: 6 }} tablet={{ size: 3 }}>
                    <StaticImage
                        alt='Stop-Sound - Soundproofing and vibration damping solution supported by Greenov'
                        src={`../../../static/logos/Stop_Sound_soundproofing_Greenov.png`}
                    />
                </Columns.Column>
                <Columns.Column mobile={{ size: 6 }} tablet={{ size: 3 }}>
                    <StaticImage
                        alt='Stop-Sound - Soundproofing and vibration damping solution supported by Sambrinvest'
                        src={`../../../static/logos/Stop_Sound_soundproofing_Sambrinvest.png`}
                    />
                </Columns.Column>
                <Columns.Column mobile={{ size: 6 }} tablet={{ size: 3 }}>
                    <StaticImage
                        alt='Stop-Sound - Soundproofing sustainable solution awarded by Solar Impluse'
                        src={`../../../static/logos/Stop_Sound_sustainable_Solar_Impulse.png`}
                    />
                </Columns.Column>
                <Columns.Column mobile={{ size: 6 }} tablet={{ size: 3 }}>
                    <StaticImage
                        alt='Stop-Sound - Soundproofing and vibration damping solution supported by Innovation Fund'
                        src={`../../../static/logos/Stop_Sound_vibration_damping_InnovationFund.png`}
                    />
                </Columns.Column>
                <Columns.Column mobile={{ size: 6 }} tablet={{ size: 3 }}>
                    <StaticImage
                        alt='Stop-Sound - Soundproofing and vibration damping solution supported by SRIW'
                        src={`../../../static/logos/Stop_Sound_biobased_SRIW.png`}
                    />
                </Columns.Column>
            </Columns>
        </EosSection>
    )
}
export default OurPartnersSection