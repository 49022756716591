import React from "react"
import { EosSection, EosCard, EosHeading, EosCtaButton, EosButton } from '../eos'
import { Columns, Element } from "react-bulma-components"

const MiddlePageCtaSection = ({ style }) => {

    return (
        <EosSection style={style ? style : { paddingTop: '0', paddingBottom: '2rem', backgroundColor: 'white' }}>
            <EosCard style={{ position: 'relative', top: '-5rem', boxShadow: '0 -20px 20px 0 rgba(0, 0, 0, 0.08)' }}>
                <EosHeading
                    centered
                    renderAs='h2'
                >
                    Looking for a partner to improve your acoustic performance ?
                </EosHeading>
                <Columns>
                    <Columns.Column
                        pt={4}
                    >
                        <Element
                            style={{
                                backgroundImage: 'url(/images/Soundproofing_vibration_damping_expert.jpg)',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                height: '100%',
                                minHeight: 250

                            }}
                        >
                        </Element>
                    </Columns.Column>
                    <Columns.Column>
                        <Element renderAs='p' textSize={5} mb={5}>
                            EOS Technology experts can enhance the soundproofing and sustainability of your products
                        </Element>
                        <Element renderAs='p' textSize={5} mb={5} textWeight='bold'>
                            Book a 30 minutes-call with our experts.
                        </Element>
                        <Element renderAs='p' textSize={5} mb={4}>
                            Let’s talk about your expectations and create THE solution you need !
                        </Element>
                        <EosCtaButton mr={3} mt={3}>Request a meeting</EosCtaButton>
                        <EosButton renderAs='a' href='mailto:info@home-eos.eu' color='grey' mt={3}>No, I'd rather email you</EosButton>
                    </Columns.Column>
                </Columns>
            </EosCard>
        </EosSection>
    )
}
export default MiddlePageCtaSection