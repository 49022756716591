import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation,  Link } from "gatsby-plugin-react-i18next"
import { Element, Columns, } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'
import { EosCards, EosVideo, EosCard, EosHeading, EosSection, EosList, EosButton, EosHero } from "../components/eos"
import MiddlePageCta from "../components/sections/middle-page-cta-section"
import OurPartnersSection from "../components/sections/our-partners-section"
import EndPageCtaSection from "../components/sections/end-page-cta-section"

const IndexPage = () => {

  const { t } = useTranslation(['page_home', 'slugs'])

  const tMeta = t('meta')
  const tHero = t('hero')
  const tSlugs = t('slugs:slugs')

  return (
    <Layout style={{ backgroundColor: '#f9f9f9' }}>
      <Seo
        title={tMeta.title}
        description={tMeta.description}
      />
      <EosHero
        title={tHero.title}
        subtitle={tHero.description}
        backgroundImageUrl='/images/Stop_Sound_soundproofing_vibration_damping.jpg'
      />
      <EosSection>
        <EosHeading renderAs='h2'>
          Stop-Sound: sustainable and soundproof polymers
        </EosHeading>
        <EosCards tablet={{ size: 6 }} desktop={{ size: 3 }}>
          <EosCard
            icon={<>
              <img
                src='/pictos/Stop_Sound_acoustic_insulation.svg'
                alt='Stop-Sound : High acoustic insulation & Strong vibration damping biopolymers'
              />
            </>}
            title='Acoustic performance'
          >
            <EosList
              items={[
                <>High Acoustic insulation</>,
                <>Strong vibration damping</>,
                <>Less noise, more confort</>
              ]}
            />
          </EosCard>
          <EosCard
            icon={<>
              <img
                src='/pictos/Stop_Sound_fire_resistance.svg'
                alt='Stop-Sound : best in-class fire-reaction and better fire resistance for your product'
              />
            </>}
            title='Fire safety'
          >
            <EosList
              items={[
                "Best in class fire-reaction",
                "Better fire resistance",
                "Higher safety"
              ]}
            />
          </EosCard>
          <EosCard
            icon={<>
              <img
                src='/pictos/Stop_Sound_VOC_free.svg'
                alt='Stop-Sound : VOC emissions free, toxic compounds free, Better indoor air quality'
              />
            </>}
            title='Health protection'
          >
            <EosList
              items={[
                "VOC emissions free",
                "Toxic compounds free ",
                "Better indoor air quality"
              ]}
            />
          </EosCard>
          <EosCard
            icon={<>
              <img
                src='/pictos/Stop_Sound_sustainable_recyclable.svg'
                alt='Stop-Sound : industrial acoustic insulation from natural compounds. Sustainable and recyclable'
              />
            </>}
            title='Sustainability'
          >
            <EosList
              items={[
                "Natural compounds",
                "Recyclable",
                "Lower carbon footprint",
              ]}
            />
          </EosCard>
        </EosCards>
      </EosSection>
      <EosSection style={{ paddingBottom: '7rem' }}>
        <EosHeading
          renderAs='h2'
        >
          Soundproofing solutions made according to your needs
        </EosHeading>
        <Element renderAs='p' textSize={5} mb={6}>We enhance the acoustic, fire and environmental performance of your product</Element>
        <EosCards>
          <EosCard
            image={
              <StaticImage
                src='../../static/images/Soundproofing_requirement.png'
                alt='Soundproofing and vibration damping match your products performances requirements'
                width={750}
                height={400}
              />
            }
            title='Technical performance'
          >
            <p>
              We adapt Stop-sound specifications to match your expected products performances and your process requirements
            </p>
          </EosCard>
          <EosCard
            image={
              <StaticImage
                src='../../static/images/Soundproofing_expert_design.jpg'
                alt='Assistance to develop the best in-class soundproofing solutions thanks to our technology experts'
                width={750}
                height={400}
              />
            }
            title='Design assistance'
          >
            <p>
              From R&amp;D to products design, Eos Technology experts assist you in developing best in class soundproofing solutions
            </p>
          </EosCard>
          <EosCard
            image={
              <StaticImage
                src='../../static/images/Soundproofing_low_carbon_footprint.png'
                alt='Reducing the carbon footprint of your systems while improving the acoustic insultation'
                width={750}
                height={400}
              />
            }
            title='Product sustainability'
          >
            <p>
              Eos Technology helps you to reduce the carbon footprint of your systems
            </p>
          </EosCard>
        </EosCards>
      </EosSection>
      <EosSection
        backgroundColor='primary'
        style={{
          paddingBottom: '12rem',
          backgroundImage: "linear-gradient(to bottom, #d0dee1 30%, #d0dee1cc 80%, #d0dee1e0), url('/images/Soundproofing_vibration_damping_in_industry.jpg')",
          backgroundSize: 'cover',
        }}
      >
        <EosHeading
          renderAs='h2'
        >
          Meeting the next generation of markets and consumers needs
        </EosHeading>
        <Element renderAs='p' textSize={5} mb={5}>
          Growing vicinity, urban and traffic density, life quality and environmental protection call for a new generation of products<br />
          Eos Technology provides biobased soundproofing solutions to address :
        </Element>
        <EosList
          textSize={5}
          mb={6}
          items={[
            "Noise at work, in residential buildings and in transports systems",
            "The impact of products on health and environment toward the 2050 zero carbon emissions target !"
          ]}
        />
        <EosCards>
          <EosCard
            image={
              <StaticImage
                src='../../static/images/Soundproofing_building.png'
                alt='Sustainable soundproofing for partition walls, dividers, doors, walls, roofs, ceilings, floors…'
                width={750}
                height={400}
              />
            }
            title={<><b>Building sector</b><br /><Element textWeight='normal'>soundproofing</Element></>}
            backgroundColor='pastel-blue-light'
          >
            <Element display='flex' flexDirection='column' justifyContent='space-between' style={{ flexGrow: 1 }}>
              <Element>
                <Element renderAs='p' mb={3}>
                  Integrated in construction elements
                  Stop-sound restore the silence of nature in buildings by reducing transmission of
                </Element>
                <EosList
                  items={[
                    "Airborne, flow and impact noise",
                    "vibrations and flanking",
                  ]}
                  checkmark='blue'
                />
              </Element>
              {/* <EosButton renderAs={Link} to={`${tSlugs['/applications']}#building-sector`} color='primary' mt={5}>Read more</EosButton> */}
            </Element>
          </EosCard>
          <EosCard
            image={
              <StaticImage
                src='../../static/images/Vibration_damping_industry.png'
                alt='Sustainable soundproofing and vibration damping for compressors, pumps, computer rooms, heath pumps, engines…'
                width={750}
                height={400}
              />
            }
            title={<><b>Industry</b><br /><Element textWeight='normal'>soundproofing</Element></>}
            backgroundColor='pastel-blue-light'
          >
            <Element display='flex' flexDirection='column' justifyContent='space-between' style={{ flexGrow: 1 }}>
              <Element>
                <Element renderAs='p' mb={3}>
                  Integrated as a vibration damping liner in industrial systems Stop-Sound makes noisy equipment quieter and sustainable
                </Element>
                <EosList
                  items={[
                    "reduce noise transmission",
                    "dampen vibrations",
                    "acts a noise barrier",
                  ]}
                  checkmark='blue'
                />
              </Element>
              {/* <EosButton renderAs={Link} to={`${tSlugs['/applications']}#industry-sector`}  color='primary' mt={5}>Read more</EosButton> */}
            </Element>
          </EosCard>
          <EosCard
            image={
              <StaticImage
                src='../../static/images/Vibration_damping_mobility.png'
                alt='Sustainable vibration damping for engine compartment, partitions and dividers on automobile, cruise ships, railways…'
                width={750}
                height={400}
              />
            }
            title={<><b>Mobility sector</b><br /><Element textWeight='normal'>vibration damping</Element></>}
            backgroundColor='pastel-blue-light'
          >
            <Element display='flex' flexDirection='column' justifyContent='space-between' style={{ flexGrow: 1 }}>
              <Element>
                <Element renderAs='p' mb={3}>
                  Installed as a double skin in transport systems Stop-Sound provides passengers and users an unsurpassed experience in acoustic comfort

                </Element>
                <EosList
                  items={[
                    "reduce noise transmission",
                    "dampen and absorb vibration",
                    "prevents flanking noises",
                  ]}
                  checkmark='blue'
                />
              </Element>
              {/* <EosButton renderAs={Link} to={`${tSlugs['/applications']}#transport-sector`} color='primary' mt={5}>Read more</EosButton> */}
            </Element>
          </EosCard>
        </EosCards>
      </EosSection>
      <MiddlePageCta />
      <EosSection
        style={{
          backgroundImage: "linear-gradient(135deg, rgba(193, 215, 172, 1) 50%, rgba(193, 215, 172, 0.5)), url('/images/Renewable_soundproofing_industry.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right',
          backgroundSize: 'contain',
        }}
      >
        <EosHeading
          renderAs='h2'
        >
          Eos Technology expertise and vision
        </EosHeading>
        <Element renderAs='p' textSize={5} mb={5}>
          Our expertise raised over years combines multiples know-how in materials and soundproofing technologies.
        </Element>
        <EosList
          mb={6}
          checkmark='blue'
          textSize={5}
          items={[
            "We assist you in developping innovative, sustainable and top class solutions",
            "EOS Technology is the leading provider of renewable solutions in soundproofing and vibration damping",
            "We believe industry can be efficient AND sustainable and we want to take a role in this revolution.",
          ]}
        />
        <Columns>
          <Columns.Column size={3}>
            <EosVideo
              embed={
                <iframe src="https://www.youtube.com/embed/1yHfGj6lpVw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen" allowFullScreen></iframe>
              }
            >
              <StaticImage
                alt='Eos Technology biobased soundproofing experts supports Bertrand Picard speech about 1000 solutions'
                src='../../static/images/video-picard.jpg'
              />
            </EosVideo>
            <Element renderAs='p' textWeight='bold' mt={1}>
              Bertrand Picard speech about 1000 solutions
            </Element>
          </Columns.Column>
          <Columns.Column size={3}>
            <EosVideo
              embed={
                <iframe src="https://www.youtube.com/embed/mfjiOX3TtC8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen" allowFullScreen></iframe>
              }
            >
              <StaticImage
                alt='Eos Technology biobased soundproofing experts support the European Green Deal Declaration'
                src='../../static/images/video-greendeal.jpg'
              />
            </EosVideo>
            <Element renderAs='p' textWeight='bold' mt={1}>
              Greendeal declaration
            </Element>
          </Columns.Column>
        </Columns>
      </EosSection>
      {/* 
      <EosSection>
        <EosHeading
          renderAs='h2'
        >
          EOS Technology latest news
        </EosHeading>
        <div className="tile is-ancestor mt-4">
          <div className="tile is-parent">
            <div className="tile is-child">
              <EosCard
                backgroundImage={'/images/RouleauStopFloorFlex.jpg'}
                contentProps={{ style: { backgroundColor: '#028BABF1', flexGrow: 0 } }}
                style={{ paddingTop: '50%' }}
              >
                <Heading textColor='white' textTransform='uppercase' size={4} renderAs='h3'>New Stop Floor materials out</Heading>
                <Heading textColor='primary-light' subtitle textFamily='secondary' size={6}>26/06/2021</Heading>
                <Element renderAs='p' textColor="white" mb={3}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat...
                </Element>
                <EosButton color='white' size='small' textColor='primary'>Read more</EosButton>
              </EosCard>
            </div>
          </div>
          <div className="tile is-vertical is-parent">
            <div className="tile is-child">
              <EosCard
                backgroundImage={'/images/news-2.png'}
                contentProps={{ style: { backgroundColor: '#028BABF1', flexGrow: 0 } }}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
              >
                <Columns>
                  <Columns.Column>
                    <Heading textColor='white' textTransform='uppercase' size={5} renderAs='h3'>EOS Technology is trusted by Solar Impulse</Heading>
                    <Heading textColor='primary-light' subtitle textFamily='secondary' size={6}>17/05/2021</Heading>

                  </Columns.Column>
                  <Columns.Column narrow display='flex'>
                    <EosButton color='white' mt='auto' size='small' textColor='primary'>Read more</EosButton>

                  </Columns.Column>
                </Columns>

              </EosCard>
            </div>
            <div className="tile is-child">
              <EosCard
                backgroundImage={'/images/news-3.png'}
                contentProps={{ style: { backgroundColor: '#028BABF1', flexGrow: 0 } }}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}
              >
                <Columns>
                  <Columns.Column>
                    <Heading textColor='white' textTransform='uppercase' size={5} renderAs='h3'>Our new warehouse</Heading>
                    <Heading textColor='primary-light' subtitle textFamily='secondary' size={6}>22/04/2021</Heading>
                  </Columns.Column>
                  <Columns.Column narrow display='flex'>
                    <EosButton color='white' mt='auto' size='small' textColor='primary'>Read more</EosButton>
                  </Columns.Column>
                </Columns>
              </EosCard>
            </div>
          </div>
        </div>
      </EosSection> */}
      <EndPageCtaSection />
      <OurPartnersSection />
    </Layout >
  )
}

export default IndexPage

export const query = graphql`
      query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
        node {
        ns
          data
      language
        }
      }
    }
  }
      `;