import React from "react"
import { EosSection, EosHeading, EosCard, EosButton, EosCtaButton } from '../eos'
import { Columns, Element } from "react-bulma-components"

const EndPageCtaSection = () => {

    return (
        <EosSection style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: 'white' }}>
        <EosCard style={{ position: 'relative', top: '5rem', boxShadow: '0 50px 20px 0 rgba(0, 0, 0, 0.08)' }}>
          <EosHeading
            centered
            renderAs='h2'
          >
            Still have questions about sustainable sound proofing ?
          </EosHeading>
          <Columns>
            <Columns.Column textAlign='center'>
              <Element renderAs='p' textSize={5} mb={4}>
                Whether you would like to solve a problem or need data’s about our product.<br />
              </Element>
              <Element renderAs='p' textSize={5} mb={4} textWeight='bold'>
                Please reach us, we are happy to help !
              </Element>
              <EosCtaButton  mr={3} mt={3}>Request a meeting</EosCtaButton>
              <EosButton mt={3} renderAs='a' href='mailto:info@home-eos.eu' color='grey'>No, I'd rather email you</EosButton>
            </Columns.Column>
          </Columns>
        </EosCard>
      </EosSection>
    )
}
export default EndPageCtaSection